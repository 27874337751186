'use client';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Modal from '../Modal';
import NextImage from '../NextImage';
import setCookie from '@src/utils/setCookie';
import getCookie from '@src/utils/getCookie';
import isBot from '@src/utils/isBot';
import { CountriesInfoPropTypes } from './GeoPopup.types';
import { usePathname } from 'next/navigation';
import HubspotForm from '@src/components/Sections/HubSpotForm';
import bdmpopup from './bdmpopup.module.css';
const GeoPopup = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const userTimeZone = dayjs.tz.guess();
  const userCountyTz = userTimeZone?.split('/')[1];

  // Load the timezone data
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const timezoneData = require('./timezone-data.json');
  const {
    countries,
    zones
  } = timezoneData;

  // Define the type for timeZoneToCountry
  const timeZoneToCountry: {
    [key: string]: string;
  } = {};
  Object.keys(zones).forEach(z => {
    const cityArr = z.split('/');
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });
  const countryCode = timeZoneToCountry[userCountyTz] ? timeZoneToCountry[userCountyTz] : '';
  const countriesInfo: CountriesInfoPropTypes = {
    DE: {
      countryName: 'Germany',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/2LiEjFdjhdjFibAVNtYF6S/7ef94dae2b10e1117f49a30955482191/popup-germany-min.png',
      introbold: 'Nimm Kontakt',
      intro: ' zu einem unserer deutschen Mitarbeiter auf',
      bdms: [{
        name: 'Vincent Simons',
        position: 'Physiotherapist & Sports Scientist'
      }, {
        name: 'John Boyer',
        position: 'Physiotherapist & Sports Scientist'
      }, {
        name: 'Andrew Quarmby',
        position: 'Sports Scientist'
      }, {
        name: 'Johannes Zimmer',
        position: 'Sports Scientist'
      }]
    },
    JP: {
      countryName: 'Japan',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3khPApVuVeAEHdzdG7THwB/d190aacc733093baf096704083f96a71/popup-japan-min.png',
      introbold: '',
      intro: ' 日本語でのお問い合わせはこちらまで',
      bdms: [{
        name: 'Motoki Miyamoto',
        position: 'Physiotherapist'
      }]
    },
    IT: {
      countryName: 'Italy',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3QmSxbYBpBTm7OoVnO8KrV/7d5f2cf9b1f64115535f980dfb72d8ac/popup-italy-min.png',
      introbold: 'Mettiti in contatto',
      intro: ' con uno dei nostri membri in Italia',
      bdms: [{
        name: 'Marco Cuniberti',
        position: 'Physiotherapist'
      }, {
        name: 'Mario Ferrante',
        position: 'Physiotherapist'
      }, {
        name: 'Davide Zottola',
        position: 'Physiotherapist & Sports Scientist'
      }]
    },
    FR: {
      countryName: 'France',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/69BQbkkcZRCIQbQAWLJdWh/c5a0088d1e3c9219e4122adf22f93aad/popup-france-min.png',
      introbold: 'Entrez en contact',
      intro: ' avec lun des membres de notre équipe en France',
      bdms: [{
        name: 'Laurent Antkowiak',
        position: 'Sports Scientist'
      }, {
        name: 'Morgan Monge',
        position: 'Sports Physiotherapist'
      }, {
        name: 'Lucas Galmiche',
        position: 'Sports Scientist'
      }]
    },
    MX: {
      countryName: 'Mexico',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4UGwrzmk6AKwwcE44VagA4/50b95019c629a5b9048debfcbcf15d9a/popup-mexico-min.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Mexico.',
      bdms: [{
        name: 'Renato Trevino',
        position: 'Sports Scientist and S&C Coach'
      }, {
        name: 'Martin Lara',
        position: 'Sports Scientist and S&C Coach'
      }]
    },
    AR: {
      countryName: 'Argentina',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3DJZbuCNIEVRvxpTVSmWVJ/95d57a10a815a8e7117ac10b30405cd8/popup-argentina-min.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Argentina.',
      bdms: [{
        name: 'Eduardo Tondelli',
        position: 'Physiotherapist and Sports Scientist'
      }]
    },
    CL: {
      countryName: 'Chile',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6ph7Vv3PF6ASJPK0M5x4Rr/775c23daf1af548fc8bfa42516815a47/popup-chile-min.png',
      introbold: 'Contacta',
      intro: ' a uno de nuestros asesores en Chile.',
      bdms: [{
        name: 'Enzo Cacciuttolo',
        position: 'Sports Scientist'
      }]
    },
    ES: {
      countryName: 'Spain',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/25XrDEhpT1lO6LvkKVctzU/8d709462d389f8f98433aff5b6e00dc9/popup-spain-min.png',
      introbold: 'Contacta',
      intro: ' nuestro equipo en España',
      bdms: [{
        name: 'Diego Soliño',
        position: 'Physiotherapist'
      }, {
        name: 'Pau Martinez',
        position: 'Physiotherapist'
      }, {
        name: 'Andrea Alesanco Fernandez',
        position: 'Physiotherapist'
      }, {
        name: 'Pedro Castellon',
        position: 'Physiotherapist'
      }, {
        name: 'María José Vergara',
        position: 'Medical Doctor'
      }]
    },
    CA: {
      countryName: 'Canada',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4dmHEzxW2VewumhI7jhE8x/bf5f3b9d4dd44b0a4d15f1947be7586e/popup-canada-min.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Canada',
      bdms: [{
        name: 'Jason McCullagh',
        position: 'S&C Coach'
      }, {
        name: 'Luke Leggo',
        position: 'S&C Coach'
      }, {
        name: 'Jonathan Geller',
        position: 'Athletic Therapist'
      }, {
        name: 'David Granito',
        position: 'Athletic Therapist'
      }, {
        name: 'Conor Blee',
        position: 'Physiotherapist'
      }]
    },
    NO: {
      countryName: 'Norway',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5ZTK8X339oTu2q4GCPGebv/954f65cdbe0a1d7634f789409c03758d/popup-norway-min.png',
      introbold: 'Kom i kontakt',
      intro: ' med et av våre teammedlemmer i Norge',
      bdms: [{
        name: 'Simen Thøgersen',
        position: 'Physiotherapist & S&C Coach'
      }, {
        name: 'Jonas Osmundsen',
        position: 'Physiotherapist & Sports Scientist'
      }, {
        name: 'Oda Lindahl',
        position: 'Manual Therapist'
      }]
    },
    SI: {
      countryName: 'Slovenia',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6nf22xbBbnByigPekKIfZK/a3f81f590a043ea5cc4e385359a85888/popup-slovenia-min.png',
      introbold: 'Pošljite sporočilo',
      intro: ' članu naše ekipe v Sloveniji',
      bdms: [{
        name: 'Sebastjan Potokar',
        position: 'Physiotherapist'
      }]
    },
    PT: {
      countryName: 'Portugal',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5OIbtzOEyWUjxc0nK3Ztck/92e9177602f0d19dbf2e899e8d353833/popup-portugal-min.png',
      introbold: 'Entre em contacto',
      intro: ' com um dos membros da nossa equipa em Portugal',
      bdms: [{
        name: 'Diego Soliño',
        position: 'Physiotherapist'
      }]
    },
    EG: {
      countryName: 'Egypt',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6mofmiyz8iLUI7aMfJ4fZs/bedf085a3e95673e1ee3522b6408d00a/popup-egypt-min.png',
      introbold: 'تواصل',
      intro: ' مع أحد أعضاء فريقنا في مصر',
      bdms: [{
        name: 'Antonios Sayed',
        position: 'Physiotherapist & Sports Scientist'
      }]
    },
    NZ: {
      countryName: 'New Zealand',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6ghcovWIoKRCZBx62xB32m/971ab57cf5428854d48fcbdce92c3429/popup-new-zealand-min.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in New Zealand',
      bdms: [{
        name: 'Kim Wilson',
        position: 'Physiotherapist'
      }, {
        name: 'Sean McCleary',
        position: 'S&C & Sports Scientist'
      }]
    },
    IE: {
      countryName: 'Ireland',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5TkoM9WI6e4WiquVIUfxg7/5424ddf8d4fde0bcf2d25ad7abac240b/popup-ireland-min.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Ireland',
      bdms: [{
        name: 'Conor Tierney',
        position: 'Physiotherapist'
      }]
    },
    PL: {
      countryName: 'Poland',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/6QtD27Dar0dL0XxL2TBAP6/14e1decedb679eb99aeaa511c870b271/popup-poland-min.png',
      introbold: 'Skontaktuj się z',
      intro: ' jednym z naszych członków zespołu w Polsce',
      bdms: [{
        name: 'Grzegorz Wojdala',
        position: 'Sports Scientist'
      }]
    },
    DK: {
      countryName: 'Denmark',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3exQU7vI1IJrQq22mfJnhB/bddc9a3716a104bfa6ab89e3be10f3f7/popup-denmark-min.png',
      introbold: 'Kom i kontakt',
      intro: ' med et af vores teammedlemmer i Danmark',
      bdms: [{
        name: 'Simen Thøgersen',
        position: 'Physiotherapist & S&C Coach'
      }, {
        name: 'Jonas Osmundsen',
        position: 'Physiotherapist & Sports Scientist'
      }]
    },
    SA: {
      countryName: 'Saudi Arabia',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/oGeSZr89RBHIKY1fphRfa/5ed9be0dad4af3633bc6eb7fa051c845/popup-saudi-arabia-min.png',
      introbold: '',
      intro: 'تواصل مع أحد أعضاء فريقنا في المملكة العربية السعودية',
      bdms: [{
        name: 'Antonios Sayed',
        position: 'Physiotherapist & Sports Scientist'
      }]
    },
    SE: {
      countryName: 'Sweden',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/2gcIoixownT6CREU9FwKF9/b0a58e02d56bc3e11577b53ac24c9d44/popup-sweden-min.png',
      introbold: 'Get in touch',
      intro: ' with one of our team members in Sweden',
      bdms: [{
        name: 'Oda Lindahl',
        position: 'Manual Therapist'
      }]
    },
    RO: {
      countryName: 'Romania',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/2uyYybniykAKLRBX0WeUFF/5efac1f783450ca02d22b9753c9e1fe0/popup-romania-min.png',
      introbold: 'Contactaţi-ne',
      intro: ' cu unul dintre membrii echipei noastre din România',
      bdms: [{
        name: 'Brandon Garcia',
        position: 'Sports Physiologist & Sports Scientist'
      }]
    },
    TR: {
      countryName: 'Turkey',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/68TjbLsI9Vr0QmXoRpSQS6/81dbb4d4e35d86e6216271abc8c8986a/popup-turkey-min.png',
      introbold: 'Bize Ulaşın',
      intro: `Türkiye'deki ekip üyelerimizden biriyle`,
      bdms: [{
        name: 'Mert Sahan',
        position: 'Physiotherapist'
      }, {
        name: 'Denizhan Isiksal',
        position: 'Sports Scientist'
      }, {
        name: 'Mahsum Akikol',
        position: 'Physiotherapist'
      }]
    },
    RS: {
      countryName: 'Serbia',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4DKLmbcySgifoKAXynjr9k/5a8b216460232b60dc4da522de192b17/popup-serbia-min.png',
      introbold: 'Контактирајте нас',
      intro: `са једним од наших чланова тима у Србији`,
      bdms: [{
        name: 'Sebastjan Potokar',
        position: 'Physiotherapist'
      }]
    },
    HR: {
      countryName: 'Croatia',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/1p7whPT1XQ6F3nGS18rhLF/01ac78cde253d4c4d736c3f82d82cb9f/popup-croatia-min.png',
      introbold: 'Kontaktirajte nas',
      intro: `s jednim od članova našeg tima u Hrvatskoj`,
      bdms: [{
        name: 'Sebastjan Potokar',
        position: 'Physiotherapist'
      }]
    },
    CH: {
      countryName: 'Switzerland',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5ogHleODlZjbkx4t5Wgv3b/4b9d9a30a5e2e335827840a8e8c49044/popup-switzerland-min.png',
      introbold: 'Get in touch',
      intro: `with one of our team members in Switzerland`,
      bdms: [{
        flag: 'https://images.ctfassets.net/98s79sqwuajy/y7N4q1aGGNabRdK0Xnm45/923070bfd7baf17f4171f25896e55a8f/DE.jpg',
        name: 'Vincent Simons',
        position: 'Sports Scientist'
      }, {
        flag: 'https://images.ctfassets.net/98s79sqwuajy/3xy2st7GnWjnCIay3PQtD5/6b6da4986902d334a281b2406efafb43/FR.jpg',
        name: 'Laurent Antkowiak',
        position: 'Sports Scientist'
      }, {
        flag: 'https://images.ctfassets.net/98s79sqwuajy/6AO3JCKaKZLq6MM1r6odlN/f040c437d5f80bae43ee4045705bd84a/IT.jpg',
        name: 'Marco Cuniberti',
        position: 'Physiotherapist'
      }, {
        name: 'Hallo!'
      }, {
        name: 'Bonjour!'
      }, {
        name: 'Ciao!'
      }]
    },
    AT: {
      countryName: 'Austria',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/3KS225yaiMBCEwdSSalS9T/44c2259032f101a575457290a7db5e8d/popup-austria-min.png',
      introbold: 'Get in touch',
      intro: `with one of our team members in Austria`,
      bdms: [{
        name: 'Lukas Arenas',
        position: 'Sports Scientist'
      }]
    },
    UY: {
      countryName: 'Uruguay',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/5lnvx0Y1EY1D9b4pvvpVof/84eaa0841748e3bfef1932cc36998196/popup-uruguay-min.png',
      introbold: 'Contacta un',
      intro: ' miembro de nuestro equipo en Uruguay',
      bdms: [{
        name: 'Eduardo Tondelli',
        position: 'Physiotherapist and Sports Scientist'
      }]
    },
    AD: {
      countryName: 'Andorra',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4exZ9Q4pg3OHoznmeX1LBP/5256c4e37f6026690db32f2071c3fed8/popup-andorra-min.png',
      introbold: 'Contacta un',
      intro: ' miembro de nuestro equipo en Andorra',
      bdms: [{
        name: 'Pau Martinez',
        position: 'Physiotherapist'
      }]
    },
    GR: {
      countryName: 'Greece',
      countryFlag: 'https://images.ctfassets.net/98s79sqwuajy/4ZMBz2UkcBDv8wiXi5w2QT/96a1f7f035d7f6dbc5a0ccfaaf5a09bd/popup-greece-min.png',
      introbold: 'Επικοινωνήστε',
      intro: ' Επικοινωνήστε με ένα από τα μέλη της ομάδας μας στην Ελλάδα',
      bdms: [{
        name: 'Giannis Stergianos',
        position: 'Physiotherapist'
      }]
    }
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const stringifiedCountryCode = String(countryCode);
  const popupCountry = countryCode ? countriesInfo[stringifiedCountryCode] : null;
  const [showModal, setShowModal] = useState(false);
  const modalInfo = popupCountry;
  const handleClose = () => {
    setShowModal(false);
    setCookie('geoPopupVisited', 'true', 7);
  };
  const addedGeoPopupCookie = getCookie('geoPopupVisited');
  const pathname = usePathname(); // Get the current page pathname
  const [previousPathname, setPreviousPathname] = useState<string | null>(null);
  const [navigationCount, setNavigationCount] = useState(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Load navigation count from sessionStorage or initialize to 0
      const storedNavigationCount = parseInt(sessionStorage.getItem('navigationCount') || '0', 10);
      setNavigationCount(storedNavigationCount);

      // If this is the first page load, just store the pathname without incrementing
      if (previousPathname === null) {
        setPreviousPathname(pathname);
      } else if (pathname !== previousPathname) {
        const updatedCount = storedNavigationCount + 1;
        sessionStorage.setItem('navigationCount', updatedCount.toString());
        setNavigationCount(updatedCount);
        setPreviousPathname(pathname);
      }
    }
  }, [pathname, previousPathname]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight) * 100;
      if (typeof window !== 'undefined' && navigationCount > 0) {
        if (scrollPercentage >= 20 && navigationCount >= 1 && modalInfo !== undefined && modalInfo !== null && !addedGeoPopupCookie && isBot(window.navigator.userAgent) === false) {
          setShowModal(true);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navigationCount, modalInfo, addedGeoPopupCookie]);
  useEffect(() => {
    if (!showModal) return; // Ensure modal is open before modifying the form
  }, [showModal]);
  const onFormReady = () => {
    setTimeout(() => {
      const countryFull = modalInfo?.countryName;
      const countryField = document.querySelector<HTMLSelectElement>('.hs-country__temp_ select.hs-input');
      if (countryField && countryFull) {
        countryField.value = countryFull;
        countryField.dispatchEvent(new Event('change', {
          bubbles: true
        }));
      }
    }, 500); // Delay to ensure the form elements are available

    const submitButton = document.querySelector<HTMLInputElement>("input[type='submit']");
    if (submitButton) {
      submitButton.setAttribute('id', 'form-popup-submit');
    }
  };
  return <div data-sentry-component="GeoPopup" data-sentry-source-file="GeoPopup.tsx">
      {showModal && <Modal className={`${isExpanded ? '!top-[1.5rem]' : '!top-[18%]'} bdm-popup !fixed max-h-[calc(100vh-3.3rem)] w-[calc(100%-2.4rem)] overflow-scroll pt-[2rem] transition-all duration-300 ease-in-out md:w-[44rem] md:px-[3.6rem]`} onClose={() => handleClose()}>
          <div className='mx-auto'>
            {modalInfo?.countryFlag && <NextImage className='mx-auto w-full max-w-[100%] overflow-hidden [&>div]:!relative [&>div]:min-h-[39rem]' imgClassName='!absolute !object-top' src={modalInfo?.countryFlag} alt='A portrait' objectFit='contain'>
                <div className='flex h-full min-h-[40rem] w-full flex-col justify-between text-center text-[1.6rem]'>
                  <p className='mb-[4rem] mt-[12rem] min-h-[6.3rem] text-[1.6rem]'>
                    <span className='block text-[4.34rem] font-bold leading-[1.2] text-primary' id='introbold'>
                      {modalInfo?.introbold}
                    </span>
                    <span className='text-[1.5rem] font-[400] text-primary' id='intro'>
                      {modalInfo?.intro}
                    </span>
                  </p>
                  <div className={`${bdmpopup.gridContainer} grid grid-flow-col grid-cols-2 gap-[1rem]`}>
                    {modalInfo?.bdms?.map((item, index) => {
                return <div className={`flex items-start ${modalInfo?.countryName == 'Switzerland' ? 'items-center' : ''} ${index >= 3 && modalInfo?.countryName == 'Switzerland' ? 'justify-end' : ''}`} key={item?.position}>
                          <div className={`my-[0.5rem] py-[0.5rem] 
                            ${index >= 3 ? 'translate-x-[-1rem] border-l-[1px] border-l-[#EC7A23] pl-[5rem]' : ''}
                            ${modalInfo?.countryName == 'Switzerland' ? '!translate-x-[unset] pb-[0rem]' : ''}`}>
                            <div className='text-left'>
                              <p className={`${index >= 3 && modalInfo?.countryName === 'Switzerland' ? `${bdmpopup.bubble} h-[2.5rem] w-[9.4rem] rounded-[3rem] bg-[#ababab] px-[2rem] text-center font-[900] leading-[2.46rem] text-[#fff]` : ''} mb-[0.5rem] text-[1.46rem] font-[400] leading-[1.6rem] text-[#777777]`}>
                                {item?.name}
                              </p>
                              <p className='bdm-popup-tracking-gtm pt-[1rem] text-[1.2rem] font-[400] leading-[1.5rem] text-primary'>
                                {item?.position}
                              </p>
                            </div>
                          </div>
                        </div>;
              })}
                  </div>
                  {!isExpanded && <button className={`${modalInfo?.countryName == 'Switzerland' ? '!relative' : ''} bdm-popup-tracking-gtm common-btn-transition right-0 bottom-0 relative ml-auto mt-[1rem] inline-flex items-center justify-center rounded-[0.6rem] border-primary bg-primary px-[2rem] py-[0.8rem] text-[1.6rem] font-[400] leading-[1.6rem] tracking-[-0.0304rem] !text-[#fff] hover:border-primary--v2 hover:bg-primary--v2 hover:text-primary--v2`} onClick={toggleExpand}>
                      Contact
                    </button>}
                </div>
              </NextImage>}
          </div>

          <div className={`transition-all duration-300 ease-in-out [&_*_.hs-submit]:pb-[4rem] [&_*label]:text-[1.5rem] [&_*label]:text-[#777777] 
            ${isExpanded ? 'block' : 'hidden max-h-[0.1rem]'}
            `}>
            <HubspotForm portalId='22106570' formId='69dfd0c6-0b5a-4842-8b88-f3728d1a3a74' formTarget='form-popup' region='na1' onFormReady={onFormReady} />
            <div className='pb-[2rem] text-[1.13rem] font-[100] leading-[1.6rem]'>
              <p>
                VALD uses the contact information you provide to us to contact you about our
                relevant content, products, and services. You may unsubscribe from these
                communications at any time.
              </p>
            </div>
          </div>
        </Modal>}
    </div>;
};
export default GeoPopup;