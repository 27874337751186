import React from 'react';
import useGsapLoaded from './useGsapLoaded';

type ParamsTypes = {
  isOpen: boolean;
};

const useDropdownStaggeredFadeAnimation = ({ isOpen }: ParamsTypes) => {
  const isGsapLoaded = useGsapLoaded();
  const listRef = React.useRef<any>({});
  const containerRef = React.useRef<any>(null); // Ref for the container
  const tlRef = React.useRef<any>(null); // Ref to store the timeline
  const fadeAnimationRef = React.useRef<any>(null); // Ref for the fade animation

  React.useEffect(() => {
    if (isGsapLoaded) {
      const tl = window?.gsap?.timeline({ paused: true });
      const anchors = listRef?.current?.children;

      // Add the staggered animation for the dropdown items
      if (anchors) {
        tl.fromTo(
          anchors,
          { x: -60, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 0.4,
            ease: 'power1.out',
            stagger: {
              amount: 0.25,
            },
          }
        );
      }

      tlRef.current = tl; // Store the timeline
    }
  }, [isGsapLoaded]);

  React.useEffect(() => {
    if (isGsapLoaded && containerRef.current) {
      // Kill any existing fade animation to prevent overlap
      fadeAnimationRef.current?.kill();

      if (isOpen) {
        // Smoothly fade in the dropdown and add interactive classes
        containerRef.current?.classList.add('pointer-events-auto', 'visible');
        containerRef.current?.classList.remove('pointer-events-none', 'invisible');

        fadeAnimationRef.current = window?.gsap?.to(containerRef.current, {
          opacity: 1,
          duration: 0.33,
          ease: 'power1.out',
        });

        // Play staggered animation for list items
        tlRef.current?.play();
      } else {
        // Reverse staggered animation for list items
        tlRef.current?.reverse();

        // Smoothly fade out the dropdown and add hiding classes after animation
        fadeAnimationRef.current = window?.gsap
          ?.to(containerRef.current, {
            opacity: 0,
            duration: 0.33,
            ease: 'power1.out',
          })
          .eventCallback('onComplete', () => {
            // Add a small delay before hiding classes
            window.setTimeout(() => {
              containerRef.current?.classList.add('pointer-events-none', 'invisible');
              containerRef.current?.classList.remove('pointer-events-auto', 'visible');
            }, 200); // 200ms delay
          });
      }
    }
  }, [isOpen, isGsapLoaded]);

  return { listRef, containerRef };
};

export default useDropdownStaggeredFadeAnimation;
